








































import Component from "vue-class-component";
import Hacienda from "../entidades/Plantacion/Hacienda";
import Vue from "vue";
import Tabla from "../components/General/TablaGeneralFinal.vue";
import Agregar from "../components/Maestro/Hacienda/Agregar.vue";
import AgregarAnioCultivo from "@/components/Maestro/Hacienda/AgregarItem.vue";
import Formulario from "../components/Formulario.vue";
import { Mutation } from "vuex-class";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";

@Component({
  components: {
    Tabla,
    Formulario,
    Agregar,
    AgregarAnioCultivo,
  },
})
export default class extends Vue {
  //Propiedades
  @Mutation("mostrarLoading", { namespace: "sistemaModule" })
  mostrarLoading!: Function;
  @Mutation("mostarAlert", { namespace: "sistemaModule" })
  mostarAlert!: Function;

  message?: String = "";
  notificacion?: Boolean = false;
  listado?: Array<Hacienda> = [];
  header: { text: string; sortable: boolean; value: string }[] = [
    { text: "Descripcion", sortable: true, value: "nombre" },
    { text: "Zona Climatica", sortable: true, value: "zonaClimatica" },
    { text: "Zona Geografica", sortable: true, value: "zonaGeografica" },
    { text: "Latitud", sortable: true, value: "latitud" },
    { text: "Longitud", sortable: true, value: "longitud" },
    { text: "Editar", value: "actions", sortable: false },
    { text: "Estado", sortable: true, value: "estado" },
  ];
  hacienda: Hacienda | undefined = new Hacienda();
  
  //releacionado al agregar el modo hacienda item
  modalHaciendaItem: boolean = false;
  modalHaciendaItemName: string = "";
  //Agregar una hacienda
  modalHaciendaName: string = "";
  modalHacienda: boolean = false;
  //todos los metods

  async Obtener() {
    try {
      this.mostrarLoading(new Loading(true, "Obteniendo Registros"));
      var rsp = await Hacienda.Obtener();
      if (rsp.isSuccess) {
        this.listado = rsp.isResult;
        console.log(this.listado);
      } else {
        this.notificacion = true;
        this.message = rsp.isMessage;
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async agregarDb() {
    try {
      this.mostrarLoading(new Loading(true, "Registrando..."));
      if (this.hacienda != undefined) {
        var rsp = await Hacienda.Agregar(this.hacienda);
        if (rsp.isSuccess) {
          this.modalHaciendaName = "";
          this.modalHacienda = false;
          this.message = rsp.isMessage;
          this.notificacion = true;
          this.hacienda = undefined;
          await this.Obtener();
        } else {
          this.notificacion = true;
          this.message = rsp.isMessage;
        }
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }
  
  async updateEstadoDb(hacienda: Hacienda) {
    try {
      this.mostrarLoading(new Loading(true, "Actualizando estado"));
      var response = await Hacienda.EditarEstado(hacienda);
      if (response.isSuccess == true) {
        this.mostarAlert(new Alerta(response.isMessage, true, "primary", 2000));
        await this.Obtener();
      } else {
        this.mostarAlert(new Alerta(response.isMessage, true, "danger", 2000));
      }
    } catch (error) {
      //@ts-ignore
      this.mostarAlert(new Alerta(error, true, "danger", 2000));
    } finally {
      this.mostrarLoading(new Loading(false, ""));
    }
  }

  async editarHaciendaModal(hacienda: Hacienda) {
      //Metodo para editar un HaciendaItem
    try {
      console.log("entramos");
      console.log(hacienda);
      this.hacienda = hacienda;
      this.modalHacienda = true;
      this.modalHaciendaName = "Agregar";
    } catch (error) {
      console.log(error);
    }
  }

  abrilModalHacienda() {
    this.hacienda = new Hacienda();
    this.modalHaciendaName = "Agregar";
    this.modalHacienda = true;
  }
  
  cerrarModalHacienda() {
    this.modalHacienda = false;
    this.modalHaciendaName = "";
    this.hacienda = undefined;
  }

  //ciclo de vida
  mounted() {
    this.Obtener();
  }
}
